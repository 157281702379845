/* eslint-disable sort-keys */

import styled from '@emotion/styled';

type Props = {
  artistDescription?: boolean;
  showDescriptionGradient?: boolean;
};

const RecReasonPrimitive = styled('span')<Props>(
  ({ artistDescription = false, showDescriptionGradient, theme }) => ({
    background: showDescriptionGradient
      ? `linear-gradient(${theme.colors.transparent.dark}, ${theme.colors.transparent.primary})`
      : 'none',
    color: theme.colors.white.primary,
    fontSize: theme.fonts.size.xsmall,
    height: '50%',
    left: artistDescription ? '10%' : 0,
    padding: showDescriptionGradient ? '.75rem' : '0',
    position: 'absolute',
    textAlign: artistDescription ? 'center' : 'inherit',
    textShadow: showDescriptionGradient
      ? `rgba(24, 24, 24, 0.9) 0 2px 5px`
      : 'none',
    top: artistDescription ? '70%' : 0,
    width: artistDescription ? '80%' : '100%',
    zIndex: 2,

    a: {
      color: showDescriptionGradient ? theme.colors.white.primary : 'inherit',
      fontWeight: 'bold',
    },

    '&.station-favorite': {
      textAlign: 'right',

      '.btn': {
        padding: 0,
      },
    },
  }),
);

export default RecReasonPrimitive;
