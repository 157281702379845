/*
 * Functions related to the lifecycle and workflow of the on demand subscription iframe.
 */

import factory from 'state/factory';
import hub, { E } from 'shared/utils/Hub';
import isBefore from 'date-fns/is_before';
import { addParams } from 'utils/url';
import { ConnectedModals } from 'state/UI/constants';
import { getExpiration, getSource } from 'state/Entitlements/selectors';
import { getIsAnonymous } from 'state/Session/selectors';
import { navigate } from 'state/Routing/actions';
import { openLoginModal, openModal } from 'state/UI/actions';
import { SUBSCRIPTION_SOURCE } from 'constants/subscriptionConstants';

const store = factory();

/*
 * Navigate to the full-page subscription iframe, setting userId and the
 * desired subscription type as query params.
 */
export function navigateToSubscriptionFrame(subscriptionId, opts = {}) {
  const state = store.getState();
  const userSubSource = getSource(state);
  const userSubscriptionEndsDate = getExpiration(state);
  const isAnonymous = getIsAnonymous(state);
  const isExpired = userSubscriptionEndsDate
    ? isBefore(new Date(userSubscriptionEndsDate), new Date())
    : false;
  // if the user is anonymous then direct them to an authentication flow
  if (isAnonymous) {
    store.dispatch(
      openLoginModal({ context: `subscribing-${subscriptionId}` }),
    );

    hub.once(E.AUTHENTICATED, () => {
      setTimeout(() => {
        navigateToSubscriptionFrame(subscriptionId, {
          ...opts,
          hardRefresh: !opts.fromWelcomePage,
        });
      }, 0);
    });
  } else if (
    !isExpired &&
    [
      SUBSCRIPTION_SOURCE.APPLE,
      SUBSCRIPTION_SOURCE.GOOGLE,
      SUBSCRIPTION_SOURCE.AMAZON,
    ].includes(userSubSource)
  ) {
    // if they were subscriped on mobile, direct them to the app store
    store.dispatch(
      openModal({
        id: ConnectedModals.DirectToAppStore,
        context: userSubSource,
      }),
    );
  } else if (__CLIENT__) {
    // otherwise navigate to subscribe
    const params = { subscriptionId };
    if (opts.upsellFrom) {
      params.upsellFrom = opts.upsellFrom.id;
    }
    if (opts.nextPage) {
      params.nextPage = opts.nextPage;
    }

    const newPath = addParams('/subscribe', params);
    if (opts.hardRefresh) {
      // XXX: https://github.com/flatiron/director/issues/312
      window.location = newPath;
    } else {
      store.dispatch(navigate({ path: newPath }));
    }
  }
}
