import BackgroundImage from 'primitives/Hero/BackgroundImage';
import HeroBackground from 'components/Hero/HeroBackground';
import HeroContainer from 'primitives/Hero/HeroContainer';
import HeroContent from 'primitives/Hero/HeroContent';
import HeroMidSection from 'primitives/Hero/HeroMidSection';
import HeroTitle from 'primitives/Hero/HeroTitle';
import NavLink from 'primitives/Typography/Links/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import Title from 'components/Hero/HeroTitle/HeroTitle';
import {
  assetUrl,
  backgroundColor,
  btnTitle,
  hasHero,
  hideHero,
  imgUrl,
  name,
  noLogo,
  primaryBackgroundSrc,
  url,
} from 'state/Hero/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { PureComponent } from 'react';
import {
  resetHero,
  setHasHero,
  setHeroPremiumBackground,
  trackItemSelected,
} from 'state/Hero/actions';
import { State } from 'state/types';

type Props = {
  assetUrl: string;
  backgroundColor: string;
  btnTitle: string;
  name?: string;
  primaryBackgroundSrc: string;
  hasHero: boolean;
  resetHero: () => void;
  setHasHero: (hasHero: boolean | null | undefined) => void;
  setHeroPremiumBackground: (
    imgUrl: string,
    backgroundColor: string,
    noLogo: boolean,
  ) => void;
  trackItemSelected: (origin: 'for_you' | 'home', url: string) => void;
  target: string | null;
  url: string;
};

class HomeHero extends PureComponent<Props> {
  static defaultProps = { name: '' };

  constructor(props: Props) {
    super(props);

    const { primaryBackgroundSrc: primaryBgSrc, assetUrl: asset } = props;
    this.props.setHasHero(!!(primaryBgSrc || asset));
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps: Props) {
    const { primaryBackgroundSrc: primaryBgSrc, assetUrl: asset } = this.props;

    if (
      primaryBgSrc !== nextProps.primaryBackgroundSrc ||
      asset !== nextProps.assetUrl
    ) {
      this.props.setHasHero(
        !!(nextProps.primaryBackgroundSrc || nextProps.assetUrl),
      );
    }
  }

  componentWillUnmount() {
    this.props.resetHero();
    this.props.setHasHero(false);
  }

  trackItemSelected = () => {
    this.props.trackItemSelected('for_you', this.props.url);
    return true;
  };

  render() {
    const {
      hasHero: doesHaveHero,
      primaryBackgroundSrc: primaryBgSrc,
      btnTitle: buttonTitle,
      backgroundColor: bgColor,
      target,
      url: link,
      name: title,
    } = this.props;

    const primaryBackground = primaryBgSrc ? (
      <BackgroundImage
        alt={title ?? ''}
        background
        css={{ '[data-src]': { backgroundSize: 'auto 100%' } }}
        src={primaryBgSrc}
      />
    ) : null;

    return (
      doesHaveHero && (
        <HeroContainer data-test="hero-container">
          <HeroBackground
            backgroundColor={bgColor}
            noMask
            primaryBackground={primaryBackground}
          />
          <HeroMidSection>
            <HeroContent>
              <HeroTitle>
                <Title clamp text={title} />
              </HeroTitle>
              {link && buttonTitle ? (
                <NavLink
                  onClick={this.trackItemSelected}
                  target={target}
                  to={link}
                  underline={false}
                >
                  <OutlinedButton styleType="light">
                    {buttonTitle}
                  </OutlinedButton>
                </NavLink>
              ) : null}
            </HeroContent>
          </HeroMidSection>
        </HeroContainer>
      )
    );
  }
}

export default connect(
  createStructuredSelector<
    State,
    {
      assetUrl: string;
      backgroundColor: string;
      btnTitle: string;
      hideHero: boolean;
      imgUrl: string;
      name: string;
      noLogo: boolean;
      primaryBackgroundSrc: string;
      hasHero: boolean;
      target: string | null;
      url: string;
    }
  >({
    assetUrl,
    backgroundColor,
    btnTitle,
    hideHero,
    imgUrl,
    name,
    noLogo,
    primaryBackgroundSrc,
    hasHero,
    target: state => state.hero.target,
    url,
  }),
  {
    resetHero,
    setHasHero,
    setHeroPremiumBackground,
    trackItemSelected,
  },
)(HomeHero);
